<template>
  <div id="wizardContent">
    <b-row class="mb-5">
      <b-col
        md="10"
        class=""
      >
        <h1>{{ $t("accessControl.contents") }}</h1>
        <h5 class="text-primary">
          {{ $t('createCont') }}
        </h5>
      </b-col>

      <b-col class="d-flex justify-content-end align-items-center">
        <b-img
          v-if="languageService"
          height="20px"
          width="30px"
          :src="languageFlags(defaultLanguage)"
          class="mr-2"
        />
        <b-button
          variant="danger"
          @click="goBack()"
        >
          {{
            $t("dataGeneric.goBack")
          }}
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.save')"
        :back-button-text="$t('dataGeneric.previous')"
        class="mb-3 p-1"
        style="justify-content: space-around;"
        @on-complete="formSubmitted"
      >
        <!-- Información del contenido tab -->
        <tab-content
          :title="$t('editContent.title2')"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="rulesContent"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("editContent.title2") }}
                </h5>
                <small class="text-muted">
                  {{ $t("createContent.subtitle") }}
                </small>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('dataGeneric.name') + ' *'">
                  <validation-provider
                    v-slot="validationContext"
                    name="Name"
                    rules="required|min:3"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('editContent.name')"
                      maxlength="150"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('contents.reference')">
                  <b-form-input
                    id="reference"
                    v-model="reference"
                    maxlength="150"
                    :placeholder="$t('contents.externalRefContent')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('category.shortDescription') + ' *'"
                    :state="errors.length > 0 ? false : null"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-input
                      id="shortDescription"
                      v-model="shortDescription"
                      :state="errors.length > 0 ? false : null"
                      maxlength="180"
                      :placeholder="$t('category.shortDescription')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('dataGeneric.description')">
                  <b-form-textarea
                    v-model="description"
                    :placeholder="$t('createContent.description')"
                    rows="4"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('contents.comment')">
                  <b-form-textarea
                    id="comment"
                    v-model="comment"
                    :placeholder="$t('contents.comment')"
                    rows="4"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group :label="$t('category.factSheet')">
                  <b-form-textarea
                    v-model="technicalDetails"
                    :placeholder="$t('category.factSheet')"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('epg.genre')">
                      <b-form-select
                        id="genre"
                        v-model="selectedGenre"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="allGenre"
                        label="text"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('epg.ageClasific')">
                      <b-form-select
                        id="clasification"
                        v-model="selectedClasification"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      >
                        <b-form-select-option :value="null">
                          {{ $t('epg.ageClasific') }}
                        </b-form-select-option>
                        <b-form-select-option
                          v-for="s in allClasification"
                          :key="s.node.id"
                          :value="s.node.id"
                        >
                          <strong :style="'color:' + s.node.color + ';'">
                            {{ s.node.initial }} - {{ s.node.description }}
                          </strong>
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-form-group :label="$t('code.type')">
                  <b-form-select
                    id="type"
                    v-model="type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                  >
                    <b-form-select-option
                      v-for="s in Object.keys(typeOptions)"
                      :key="s"
                      :value="s"
                    >
                      <strong>
                        {{ $t(typeOptions[s]) }}
                      </strong>
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('quality')">
                  <b-form-select
                    v-model="quality"
                    class="mr-1"
                  >
                    <b-form-select-option
                      v-for="typ in Object.keys(qualityOptions)"
                      :key="typ"
                      :value="typ"
                    >
                      {{ qualityOptions[typ] }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row style="justify-content: left">
              <b-col md="6">
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('contents.datePublishing')"
                >
                  <flat-pickr
                    id="example-datepicker"
                    v-model="emisionDate"
                    class="form-control"
                    :placeholder="$t('contents.datePublishing')"
                    :config="{
                      enableTime: true,
                      enableSeconds: true,
                      dateFormat: 'Y-m-d H:i:S'
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('durCont')"
                  class="mb-2 mr-1"
                >
                  <b-form-input
                    id="duration"
                    v-model="duration"
                    autocomplete="new-password"
                    type="number"
                    :min="0"
                    step="any"
                    :placeholder="$t('durCont')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group :label="$t('canDownload')">
                  <b-form-checkbox
                    id="isDownload"
                    v-model="isDownload"
                    name="isDownload"
                    :disabled="disabledDownload"
                    :switch="true"
                  >
                    {{ $t('contDownlable') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('settingTabs.ads')">
                  <b-form-checkbox
                    id="anuncios"
                    v-model="ads"
                    name="anuncios"
                    :disabled="disabledAdd"
                    :switch="true"
                  >
                    {{ $t("settingTabs.ads") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('createContent.payContent')">
                  <b-form-checkbox
                    id="contenido"
                    v-model="premium"
                    name="contenido"
                    :switch="true"
                  >
                    {{ $t("createContent.payContent") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="$t('hasSub')">
                  <b-form-checkbox
                    id="hasSubtitle"
                    v-model="hasSubtitle"
                    name="hasSubtitle"
                    :switch="true"
                  >
                    {{ $t('subt') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Archivos del contenido  tab -->
        <tab-content
          :title="$t('category.filesContent')"
          :before-change="validationFormImage"
        >
          <validation-observer
            ref="ArchivosRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("category.filesContent") }}
                </h5>
                <small class="text-muted">{{ $t("createContent.load") }}</small>
              </b-col>
              <b-col
                cols="12"
                class="mb-2"
              >
                <b-form-group
                  class="mb-2 mr-1"
                  :label="$t('resources.originMedia')"
                >
                  <b-form-select
                    v-model="mediaOriginSelected"
                    class="mr-1"
                    :options="mediaOriginOptions"
                    @change="onChange()"
                  />
                </b-form-group>
                <div>
                  <!-- FILA TV/WEB -->
                  <b-form-group class="m-1">
                    <label><strong>TV/Web</strong></label>
                    <b-row style="justify-content: space-around;;">
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? imageData : imageDataUrl"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'imageData'"
                        :is-required="true"
                        :label="$t('contents.imgInternal')"
                        @saveImage="loadImage"
                      />
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? imageDataBck : imageDataBckUrl"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'imageDataBck'"
                        :label-info="$t('infoImage.background')"
                        :label="$t('contents.bckgInternal')"
                        @saveImage="loadImage"
                      />
                      <b-col cols="3" />
                    </b-row>
                  </b-form-group>
                  <!-- FILA MÓVIL -->
                  <b-form-group class="m-1">
                    <label><strong>{{ $t('Movil') }}</strong></label>
                    <b-row style="justify-content: space-around;;">
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? imageDataMob : imageDataMobUrl"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'imageDataMob'"
                        :label="$t('contents.mobileImgInternal')"
                        @saveImage="loadImage"
                      />
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? backgroundMobile : backgroundMobileUrl"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'backgroundMobile'"
                        :label-info="$t('infoImage.backgroundMobile')"
                        :label="$t('backMov')"
                        @saveImage="loadImage"
                      />
                      <b-col cols="3" />
                    </b-row>
                  </b-form-group>
                  <!-- FILA RECURSOS VARIOS -->
                  <b-form-group class="m-1">
                    <label><strong>{{ $t('otherRes') }}</strong></label>
                    <b-row style="justify-content: space-around;;">
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? imageDataAlt : imageDataAltUrl"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'imageDataAlt'"
                        :label-info="$t('infoImage.alternative')"
                        :label="$t('editContent.image')"
                        @saveImage="loadImage"
                      />
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? imageDataTit : imageDataTitUrl"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'imageDataTit'"
                        :label-info="$t('infoImage.title')"
                        :label="$t('contents.titleImgInternal')"
                        @saveImage="loadImage"
                      />
                      <image-element-vue
                        :image-origin="mediaOriginSelected == 'BUK' ? imageDataTr : imageDataTrUrl"
                        :media-origin-selected="mediaOriginSelected"
                        :image-type="'imageDataTr'"
                        :label="$t('contents.trailerInterno')"
                        :video="true"
                        @saveImage="loadImage"
                      />
                    </b-row>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content title="SEO">
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                SEO
              </h5>
              <small class="text-muted">{{ $t('fieldSeo') }}</small>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('title')">
                <b-form-input
                  id="title"
                  v-model="seoTitle"
                  maxlength="80"
                  :placeholder="$t('title')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('dataGeneric.description')">
                <b-form-input
                  id="descriptionSeo"
                  v-model="seoDescription"
                  maxlength="180"
                  :placeholder="$t('dataGeneric.description')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <!-- Media-->
        <tab-content
          v-if="type != 'MCH'"
          title="Media"
          :before-change="validationFormMedia"
        >
          <validation-observer
            ref="mediaRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Media <span v-if="isResourceRequired">*</span>
                </h5>
                <small class="text-muted"> {{ $t("category.media") }}</small>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="resourceName"
                  :rules="isResourceRequired ? 'required' : ''"
                >
                  <b-form-group
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div @click="showModal">
                      <b-form-input
                        id="resourceName"
                        v-model="resourceName"
                        disabled
                        :state="errors.length > 0 ? false : null"
                      />
                    </div>
                  </b-form-group>
                </validation-provider><br>
                <b-button
                  active
                  @click="showModal"
                >
                  {{ $t("createContent.sel") }}
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="resourceUrl != null">
              <b-col>
                <video
                  id="demo"
                  width="650"
                  height="300"
                  class="video-js vjs-default-skin vjs-big-play-centered"
                  data-setup="{&quot;liveui&quot;: true}"
                  :src="resourceUrl"
                  controls
                  @durationchange="durationVideo()"
                  @loadeddata="videoMaker()"
                  @loadstart="videoMaker()"
                >
                  <source
                    v-if="resourceUrl.includes('m3u8')"
                    :src="resourceUrl"
                    type="application/x-mpegURL"
                  >
                  <source
                    v-else
                    :src="resourceUrl"
                    type="video/mp4"
                  >
                </video>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>

    <b-modal
      ref="my-modal"
      size="xl"
      hide-footer
      :title="$t('createContent.sel')"
    >
      <div class="d-block text-center">
        <resources-selector
          :msg="tipo"
          :sponsor="isSponsor"
          :initial="true"
          :special="type"
          :audio="type == 'AUC' ? true : false"
          @data="seleccionarRecurso"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import * as constants from '@core/utils/constants'
import 'videojs-markers'
import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import {
  utf8ToB64, addContentsToCategory, isEmpty, limitCharacter, showToast, messageError, base64Encode,
} from '@/store/functions'
import { FormWizard, TabContent } from 'vue-form-wizard'
import languageFlags from '@/@core/utils/languageFlags'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import flatPickr from 'vue-flatpickr-component'
import { getUserData } from '@/auth/utils'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import {
  BFormSelectOption,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BImg,
  BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'
import configContentType from '@core/utils/contentType'
import { getQualityOptions } from '@/@core/utils/utils'
import ImageElementVue from '@/views/components/ImageElement.vue'
import ResourcesSelector from '../../common/ResourcesSelector.vue'

export default {
  components: {
    ResourcesSelector,
    BImg,
    BFormSelectOption,
    flatPickr,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormSelect,
    BFormTextarea,
    BRow,
    BCol,
    ImageElementVue,
    BFormGroup,
    BFormInput,
    BOverlay,
  },
  data() {
    return {
      isResourceRequired: true,
      hasSubtitle: false,
      quality: 'No_Quality',
      qualityOptions: getQualityOptions(),
      technicalDetails: null,
      duration: 0,
      allClasification: [],
      allGenre: [{ value: null, text: this.$t('contents.genreContent') }],
      selectedGenre: null,
      selectedClasification: null,
      seoTitle: '',
      seoDescription: '',
      resourceUrl: null,
      order: null,
      imageDataSrc: null,
      imageDataAltSrc: null,
      imageDataMobSrc: null,
      imageDataBckSrc: null,
      imageDataTitSrc: null,
      backgroundMobile: null,
      userData: getUserData(),
      isSponsor: false,
      backgroundMobileURL: null,
      backgroundMobileSrc: null,
      imageDataTrSrc: null,
      emisionDate: null,
      video: null,
      mediaOriginSelected: 'BUK',
      defaultLanguage: '',
      languageService: false,
      mediaOriginOptions: [
        { value: 'BUK', text: this.$t('dataGeneric.internal') },
        { value: 'DIR', text: this.$t('dataGeneric.external') },
      ],
      imageContentSelectedPlaceholder: this.$t('code.add'),
      imageAlternativeContentSelectedPlaceholder: this.$t('code.add'),
      mobileImageContentPlaceholder: this.$t('code.add'),
      backgroundContentPlaceholder: this.$t('code.add'),
      titleImageContentPlaceholder: this.$t('code.add'),
      trailerContentPlaceholder: this.$t('code.add'),
      resourceName: '',
      resourceId: '',
      resources: null,
      tipo: 'VID',
      imageClicked: '',
      ads: false,
      premium: false,
      imageData: null,
      imageDataMob: null,
      shortDescription: '',
      imageDataTr: null,
      imageDataAlt: null,
      imageDataTit: null,
      imageDataBck: null,
      selectedLanguage: null,
      selectedStatus: null,
      name: '',
      terminado: false,
      description: '',
      comment: '',
      inputs: null,
      typeOptions: configContentType(true),
      isDownload: false,
      required,
      imageDataUrl: '',
      imageDataMobUrl: '',
      imageDataAltUrl: '',
      imageDataTitUrl: '',
      imageDataBckUrl: '',
      backgroundMobileUrl: '',
      imageDataTrUrl: '',
      languageName: null,
      reference: null,
      status: [
        { value: 'nothing_selected', text: this.$t('code.nothing') },
        { value: 'RED', text: this.$t('code.desactivado') },
        { value: 'YLW', text: this.$t('dataGeneric.internal') },
        { value: 'GRE', text: this.$t('ecommerce.public') },
      ],
      show: false,
      type: 'NOR',
      isPod: false,
      disabledAdd: false,
      categoryId: this.$route.params.id != null ? this.$route.params.id : null,
      disabledDownload: false,
    }
  },
  watch: {
    backgroundMobile(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.backgroundMobileSrc = value
            })
            .catch(() => {
              this.backgroundMobileSrc = null
            })
        } else {
          this.backgroundMobileSrc = null
        }
      }
    },
    imageData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataSrc = value
            })
            .catch(() => {
              this.imageDataSrc = null
            })
        } else {
          this.imageDataSrc = null
        }
      }
    },
    imageDataAlt(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataAltSrc = value
            })
            .catch(() => {
              this.imageDataAltSrc = null
            })
        } else {
          this.imageDataAltSrc = null
        }
      }
    },
    imageDataMob(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataMobSrc = value
            })
            .catch(() => {
              this.imageDataMobSrc = null
            })
        } else {
          this.imageDataMobSrc = null
        }
      }
    },
    imageDataBck(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataBckSrc = value
            })
            .catch(() => {
              this.imageDataBckSrc = null
            })
        } else {
          this.imageDataBckSrc = null
        }
      }
    },
    imageDataTit(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataTitSrc = value
            })
            .catch(() => {
              this.imageDataTitSrc = null
            })
        } else {
          this.imageDataTitSrc = null
        }
      }
    },
    imageDataTr(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageDataTrSrc = value
            })
            .catch(() => {
              this.imageDataTrSrc = null
            })
        } else {
          this.imageDataTrSrc = null
        }
      }
    },
    duration() {
      function checkNumberType(value) {
        if (Number.isFinite(value)) {
          return true
        }
        return false
      }
      try {
        this.duration = checkNumberType(this.duration) ? this.duration.toFixed(2) : this.duration
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
    if (mode === 'develop' || mode === 'sandbox' || mode === 'development') {
      this.isResourceRequired = false
    }
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = this.userData.groups.edges[0].node.name === constants.sponsor
    }

    if (!isEmpty(this.categoryId)) this.getCheckCat(this.categoryId)
    this.allLanguages()
    this.fetchData()
    this.getOrder()
    this.getDefaultLanguage()
    this.clasification()
    this.genre()
    this.getCheckClient()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },

  methods: {
    languageFlags,
    getDefaultLanguage() {
      axios
        .post('', {
          query: `
             query{
             client(id:"${this.userData.profile.client.id}"){
                 defaultLanguage
                 availableLanguages
             }
         }
         `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          this.defaultLanguage = res.data.data.client.defaultLanguage
          const arr = availableLanguages.split('|')
          this.languageService = arr.length > 1
        })
        .catch(() => {
        })
    },
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave === 'required') {
        return this.$t('purchases.nameFormValid')
      }
      if (existeClave === 'min') {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'imageData':
          if (this.mediaOriginSelected === 'BUK') this.imageData = data
          else this.imageDataUrl = data
          break
        case 'imageDataBck':
          if (this.mediaOriginSelected === 'BUK') this.imageDataBck = data
          else this.imageDataBckUrl = data
          break
        case 'imageDataMob':
          if (this.mediaOriginSelected === 'BUK') this.imageDataMob = data
          else this.imageDataMobUrl = data
          break
        case 'backgroundMobile':

          if (this.mediaOriginSelected === 'BUK') this.backgroundMobile = data
          else this.backgroundMobileUrl = data
          break
        case 'imageDataAlt':
          if (this.mediaOriginSelected === 'BUK') this.imageDataAlt = data
          else this.imageDataAltUrl = data
          break
        case 'imageDataTit':
          if (this.mediaOriginSelected === 'BUK') this.imageDataTit = data
          else this.imageDataTitUrl = data
          break
        case 'imageDataTr':
          if (this.mediaOriginSelected === 'BUK') this.imageDataTr = data
          else this.imageDataTrUrl = data
          break

        default:
          break
      }
    },
    getCheckCat(id) {
      axios
        .post('', {
          query: `
        {
        allCategories(id:"${id}") {
          edges {
            node {
              id
              type
            }
          }
        }
      }
        `,
        })
        .then(result => {
          messageError(result, this)

          const { type } = result.data.data.allCategories.edges[0].node
          if (type === 'POD') {
            this.isPod = true
            this.typeOptions = configContentType(true)
            this.type = 'AUC'
          }
        })
        .catch(() => { })
    },
    getCheckClient() {
      axios
        .post('', {
          query: `
        {
          allClients(clientId:"${this.userData.profile.client.clientId}") {
            edges {
              node {
                isLinkCategories
                isDownload
                isAds

              }
            }
          }
        }
        `,
        })
        .then(result => {
          messageError(result, this)
          this.ads = result.data.data.allClients.edges[0].node.isAds
          this.disabledAdd = !result.data.data.allClients.edges[0].node.isAds
          this.disabledDownload = !result.data.data.allClients.edges[0].node
            .isDownload
        })
        .catch(() => { })
    },
    allLanguages() {
      axios
        .post('', {
          query: `
        query{
            allLanguages{
              edges{
                node{
                  language
                  locale
                  id
                }
              }
            }
          }
        `,
        })
        .then(response => {
          messageError(response, this)

          this.languageName = response.data.data.allLanguages.edges
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    chooseType(input) {
      switch (input) {
        case 'fileInputTr':
          // this.tipo = "VID";
          break
        default:
          // this.tipo = "IMG";
          break
      }
    },

    formSubmitted() {
      const headers = {
        'Accept-Language': this.defaultLanguage,
        'Content-Language': this.defaultLanguage,
      }
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      if (this.type === 'MCH') {
        this.terminado = true
      }

      if (this.terminado) {
        this.show = true
        const query = `
            mutation ($name: String!, $seoDescription: String,$seoTitle: String,$description: String,$shortDescription: String,$hasSubtitle: Boolean,
            $quality: ContentQualityInput, $duration: Int,$comment: String, $client: ID,$technicalDetails: String
            ${this.emisionDate != null ? ',$emisionDate: DateTime' : ''}){
              createContents(input:{
                name: $name,
                client: $client,
                description: $description,
                shortDescription: $shortDescription,
                technicalDetails: $technicalDetails,
                seoDescription: $seoDescription,
                seoTitle: $seoTitle,
                hasSubtitle: $hasSubtitle,
                quality: $quality,
                comment: $comment,
                duration: $duration,
                type:${this.type},
                isDownload:${this.isDownload},
                ${this.userData.isSuperuser ? '' : `creator:"${utf8ToB64(`id:${this.userData.id}`)}",`}
                mediaLocation:${this.mediaOriginSelected},       
              
              ${!isEmpty(this.emisionDate) ? 'emisionDate: $emisionDate,' : ''}


              ${!isEmpty(this.$data.selectedLanguage)
    ? `language: "${this.$data.selectedLanguage}",`
    : ''
}            
              
          
              isAds: ${this.$data.ads},
             
              isPremium:${this.$data.premium},
               ${!isEmpty(this.reference) ? `reference: "${this.reference}",` : ''
}
               ${!isEmpty(this.resourceId)
    ? `resources: "${this.resourceId}",`
    : ''
}
               ${!isEmpty(this.selectedGenre)
    ? `genre: "${this.selectedGenre}",`
    : ''
}
               ${!isEmpty(this.selectedClasification)
    ? `clasification: "${this.selectedClasification}",`
    : ''
}
              state:RED,
              isActive:false,
              categories: ["${this.categoryId}"]
              }){
            content{
              id
              emisionDate
              name
              nameEs
              nameCaEs
              client{
                name
                id
              }
            }
          }
          }
        `
        let duration = 0
        if (!isEmpty(this.durationMedia)) {
          duration = this.durationMedia === 0
            ? this.duration * 60
            : this.durationMedia
        } else {
          duration = this.duration * 60
        }

        const variables = {
          name: this.$data.name,
          client: this.userData.profile.client.id,
          description: this.$data.description,
          shortDescription: this.shortDescription,
          technicalDetails: this.technicalDetails,
          seoDescription: isEmpty(this.seoDescription) ? limitCharacter(this.shortDescription, 80) : this.seoDescription,
          seoTitle: isEmpty(this.seoTitle) ? limitCharacter(this.$data.name, 80) : this.seoTitle,
          hasSubtitle: this.hasSubtitle,
          quality: this.quality !== 'No_Quality' ? this.quality : null,
          duration,
          comment:
            this.comment !== '' && this.comment != null ? this.comment : '',
          emisionDate:
            this.emisionDate != null ? this.fechaFormato(this.emisionDate) : '',
        }
        data.append('variables', JSON.stringify(variables))
        data.append('query', query)

        axios.post('', data, { headers }, config).then(res => {
          messageError(res, this)

          if (res.data.data.createContents != null) {
            const { id } = res.data.data.createContents.content

            showToast(this.$t('success'), 1, this)

            const contentID = utf8ToB64(`content:${id}`)
            if (this.type !== 'MCH' && !isEmpty(this.resourceId)) {
              axios
                .post('', {
                  query: `{
                  contentsResource(content:"${contentID}",resource:"${utf8ToB64(
  `id:${this.resourceId}`,
)}") {
                    totalCount
                    edges {
                      node {
                        id
                        isInitial
                        content
                        resource {
                          id
                          name
                        }
                      }
                    }
                  }
                  }`,
                })
                .then(result => {
                  messageError(result, this)

                  axios
                    .post('', {
                      query: `mutation{
                   updateContentsResource(id:"${result.data.data.contentsResource.edges[0].node.id}",input:{isInitial:true}){
                     contentResource2 {
                            id

                          }
                        }
                      }`,
                    })
                    .then(r => {
                      messageError(r, this)
                    })
                    .catch(err => {
                      console.log(err)
                    })
                })
                .catch(err => {
                  console.log(err)
                })
            }

            const data2 = new FormData()
            let query2 = ''
            switch (this.mediaOriginSelected) {
              case 'BUK':

                query2 = `
                mutation{
                  updateContents(id:"${id}",input:{
                    client: "${this.userData.profile.client.id}"
                  }){
                        content{
                          id
                          name
                          image
                          titleImage
                          alternativeImage
                          imageMobile
                          background
                          trailer
                        }
                      }
                    }
              `
                data2.append('query', query2)
                data2.append('image', this.imageData)
                data2.append('alternative_image', this.imageDataAlt)
                data2.append('image_mobile', this.imageDataMob)
                data2.append('background', this.imageDataBck)
                data2.append('background_mobile', this.backgroundMobile)
                data2.append('title_image', this.imageDataTit)
                data2.append('trailer', this.imageDataTr)

                axios
                  .post('', data2, { headers }, config)
                  .then(createResponse => {
                    messageError(createResponse, this)

                    this.show = false
                    if (!isEmpty(this.categoryId)) {
                      addContentsToCategory(this.categoryId, [id]).then(() => {
                        this.$router.push({
                          name: 'contents-subcategories',
                          params: { id: this.categoryId },
                        })
                      }).catch(err => {
                        showToast(this.$t('error'), 2, this)
                        this.$router.push({
                          name: 'contents-subcategories',
                          params: { id: this.categoryId },
                        })
                        console.log(err)
                      })
                    } else {
                      this.$router.push({ name: 'contents-categories' })
                    }
                  })
                  .catch(error => {
                    console.log(error)
                    this.show = false
                    showToast(this.$t('errorSave'), 0, this)

                    if (!isEmpty(this.categoryId)) {
                      this.$router.push({
                        name: 'contents-subcategories',
                        params: { id: this.categoryId },
                      })
                    } else {
                      this.$router.push({ name: 'contents-categories' })
                    }
                  })
                break
              case 'DIR':
                axios
                  .post('', {
                    variables: {
                      imageUrl: this.imageDataUrl,
                      imageMobileUrl: this.imageDataMobUrl,
                      alternativeImageUrl: this.imageDataAltUrl,
                      titleImageUrl: this.imageDataTitUrl,
                      backgroundUrl: this.imageDataBckUrl,
                      backgroundMobileUrl: this.backgroundMobileUrl,
                      trailerUrl: this.imageDataTrUrl,
                    },
                    query: `
                          mutation($imageUrl: String,$imageMobileUrl: String,$alternativeImageUrl: String,$titleImageUrl: String,$backgroundUrl: String,$backgroundMobileUrl: String,$trailerUrl: String){
                          updateContents(id:"${id}", input:{
                          imageUrl: $imageUrl,
                          alternativeImageUrl: $alternativeImageUrl,
                          imageMobileUrl: $imageMobileUrl,
                          titleImageUrl: $titleImageUrl,
                          backgroundUrl: $backgroundUrl,
                          backgroundMobileUrl: $backgroundMobileUrl,
                          trailerUrl: $trailerUrl,
                          mediaLocation: DIR }){
                            content{
                              id
                              name
                              mediaLocation
                              backgroundMobileUrl
                              backgroundUrl
                              titleImageUrl
                              imageMobileUrl
                              alternativeImageUrl
                              imageUrl
                            }
                          }
                        }
                      `,
                  }, { headers })
                  .then(r => {
                    messageError(r, this)

                    this.show = false
                    if (!isEmpty(this.categoryId)) {
                      addContentsToCategory(this.categoryId, [id]).then(() => {
                        this.$router.push({
                          name: 'contents-subcategories',
                          params: { id: this.categoryId },
                        })
                      }).catch(err => {
                        showToast(this.$t('error'), 2, this)
                        this.$router.push({
                          name: 'contents-subcategories',
                          params: { id: this.categoryId },
                        })
                        console.log(err)
                      })
                    } else {
                      this.$router.push({ name: 'contents-categories' })
                    }
                  })
                  .catch(error => {
                    console.log(error)
                    this.show = false
                    showToast(this.$t('error'), 0, this)

                    if (!isEmpty(this.categoryId)) {
                      this.$router.push({
                        name: 'contents-subcategories',
                        params: { id: this.categoryId },
                      })
                    } else {
                      this.$router.push({ name: 'contents-categories' })
                    }
                  })

                break
              default:
                break
            }
          } else {
            this.show = false
            showToast(this.$t('error'), 0, this)

            if (!isEmpty(this.categoryId)) {
              this.$router.push({
                name: 'contents-subcategories',
                params: { id: this.categoryId },
              })
            } else {
              this.$router.push({ name: 'contents-categories' })
            }
          }
        })
      }
    },
    validationFormImage() {
      return new Promise((resolve, reject) => {
        this.$refs.ArchivosRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.rulesContent.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationFormMedia() {
      return new Promise((resolve, reject) => {
        this.$refs.mediaRules.validate().then(success => {
          if (success) {
            this.terminado = true
            resolve(true)
          } else {
            this.terminado = false
            reject()
          }
        })
      })
    },
    onChange() {
      this.imageClicked = null
      this.imageData = null
      this.imageDataAlt = null
      this.imageDataMob = null
      this.imageDataBck = null
      this.backgroundMobile = null
      this.imageDataTit = null
      this.imageDataTr = null
    },
    chooseImage(imageClicked) {
      this.imageClicked = imageClicked
      if (this.mediaOriginSelected === 'BUK') {
        document.getElementById(imageClicked).click()
      } else if (this.mediaOriginSelected === 'DIR') {
        this.$swal({
          title: this.$t('code.indicar'),
          input: 'url',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: this.$t('dataGeneric.save'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          showLoaderOnConfirm: true,
        }).then(result => {
          if (result.value) {
            this.processImageData(result.value)
          }
        })
      }
    },
    onSelectFile() {
      let input = ''
      let files = ''
      switch (this.imageClicked) {
        case 'fileInput':
          input = this.$refs.fileInput
          files = input.files
          if (files && files[0]) {
            const reader = new FileReader()
            reader.onload = e => {
              this.imageData = e.target.result
            }
            reader.readAsDataURL(files[0])
            this.$emit('input', files[0])
          }
          break
        case 'fileInputAlt':
          input = this.$refs.fileInputAlt
          files = input.files
          if (files && files[0]) {
            const reader = new FileReader()
            reader.onload = e => {
              this.imageDataAlt = e.target.result
            }
            reader.readAsDataURL(files[0])
            this.$emit('input', files[0])
          }

          break
        case 'fileInputMob':
          input = this.$refs.fileInputMob
          files = input.files
          if (files && files[0]) {
            const reader = new FileReader()
            reader.onload = e => {
              this.imageDataMob = e.target.result
            }
            reader.readAsDataURL(files[0])
            this.$emit('input', files[0])
          }
          break
        case 'fileInputBck':
          input = this.$refs.fileInputBck
          files = input.files
          if (files && files[0]) {
            const reader = new FileReader()
            reader.onload = e => {
              this.imageDataBck = e.target.result
            }
            reader.readAsDataURL(files[0])
            this.$emit('input', files[0])
          }

          break
        case 'fileInputBckMob':
          input = this.$refs.fileInputBckMob
          files = input.files
          if (files && files[0]) {
            const reader = new FileReader()
            reader.onload = e => {
              this.backgroundMobile = e.target.result
            }
            reader.readAsDataURL(files[0])
            this.$emit('input', files[0])
          }

          break
        case 'fileInputTit':
          input = this.$refs.fileInputTit
          files = input.files
          if (files && files[0]) {
            const reader = new FileReader()
            reader.onload = e => {
              this.imageDataTit = e.target.result
            }
            reader.readAsDataURL(files[0])
            this.$emit('input', files[0])
          }
          break
        case 'fileInputTr':
          input = this.$refs.fileInputTr
          files = input.files
          if (files && files[0]) {
            const reader = new FileReader()
            reader.onload = e => {
              this.imageDataTr = e.target.result
            }
            reader.readAsDataURL(files[0])
            this.$emit('input', files[0])
          }

          break
        default:
          break
      }

      this.imageClicked = ''
    },
    processImageData(data) {
      switch (this.imageClicked) {
        case 'fileInput':
          this.imageData = data
          this.imageClicked = ''
          break
        case 'fileInputAlt':
          this.imageDataAlt = data
          this.imageClicked = ''
          break
        case 'fileInputMob':
          this.imageDataMob = data
          this.imageClicked = ''

          break
        case 'fileInputBck':
          this.imageDataBck = data
          this.imageClicked = ''

          break
        case 'fileInputBckMob':
          this.backgroundMobile = data
          this.imageClicked = ''

          break
        case 'fileInputTit':
          this.imageDataTit = data
          this.imageClicked = ''
          break
        case 'fileInputTr':
          this.imageDataTr = data
          this.imageClicked = ''
          break
        default:
          break
      }
    },
    confirmData(input) {
      this.imageClicked = input

      if (this.mediaOriginSelected === 'BUK') {
        this.chooseImage()
      } else if (this.mediaOriginSelected === 'DIR') {
        this.$swal({
          title: this.$t('code.indicar'),
          input: 'url',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: this.$t('dataGeneric.save'),
          cancelButtonText: this.$t('dataGeneric.cancel'),
          showLoaderOnConfirm: true,
        }).then(result => {
          if (result.value) {
            this.processImageData(result.value)
          }
        })
      }
    },

    fetchData() {
      axios
        .post('', {
          query: `
           query
                {
                  allResources(client:"${this.userData.profile.client.id}"
                  ${this.isSponsor ? `,creator:"${utf8ToB64(`id:${this.userData.id}`)}"` : ''}
                  ){
                    edges{
                      node{
                        id
                        name
                        type
                        description
                        file{
                          fileUrl
                        }
                      }
                    }
                  }
                }
        `,
        })
        .then(response => {
          messageError(response, this)

          this.resources = response.data.data.allResources.edges
        })
    },
    goBack() {
      window.history.back()
    },
    seleccionarRecurso(data) {
      this.hideModal()
      const [id, name, url] = data
      this.resourceName = name
      this.resourceId = id
      this.resourceUrl = url
      showToast(this.$t('code.recSel', { resourceName: this.resourceName }), 1, this)
    },
    durationVideo() {
      /* TODO: Revisar duration del file */
      const v = document.getElementById('demo')

      v.addEventListener(
        'loadeddata',
        function () {
          this.durationMedia = v.duration
        },
        true,
      )
    },
    getOrder() {
      axios
        .post('', {
          query: `
                  query{
            allContents(client:"${this.userData.profile.client.id}"){
              totalCount
              edges{
                node{
                  id
                  name
                  order
                }
              }
            }
          }
        `,
        })
        .then(response => {
          messageError(response, this)

          this.order = response.data.data.allContents.totalCount
        })
    },

    videoMaker() {
      this.video = videojs('demo')

      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },
    genre() {
      axios
        .post('', {
          query: `
            query{
            allGenre(client:"${this.userData.profile.client.id}") {
              edges {
                node {
                  id
                  name
                  description
                  isActive

                }
              }
            }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allGenre.edges.forEach(element => {
            if (element.node.isActive) {
              this.allGenre.push({
                value: element.node.id,
                text: element.node.name,
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    clasification() {
      const { id } = this.userData.profile.client
      axios
        .post('', {
          query: `
          query{
            allClasification {
              edges {
                node {
                  id
                  type
                  initial
                  description
                  color
                   clasificationsClient {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
            }
        `,
        })
        .then(result => {
          messageError(result, this)

          result.data.data.allClasification.edges.forEach(element => {
            if (element.node.clasificationsClient.edges[0] != null) {
              element.node.clasificationsClient.edges.forEach(a => {
                if (a.node.id === id) {
                  this.allClasification.push(element)
                }
              })
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    fechaFormato(value) {
      const fecha = new Date(value)
      return value != null ? fecha : value
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#wizardContent .modal {
  overflow-y: auto;
}

#wizardContent .row {
  justify-content: space-around;
}

/*
Full screen Modal
*/
#wizardContent .fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 768px) {
  #wizardContent .fullscreen-modal .modal-dialog {
    width: 750px;
  }
}

@media (min-width: 992px) {
  #wizardContent .fullscreen-modal .modal-dialog {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #wizardContent .fullscreen-modal .modal-dialog {
    width: 1170px;
  }
}

#wizardContent .dropContainer {
  border: 3px dashed;
}
</style>
